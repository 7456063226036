import { useCallback } from 'react';
import * as React from 'react';

import {
  LightBulbIcon,
} from '@heroicons/react/24/solid';
import { Alert } from '@material-tailwind/react';
import { toast } from 'react-toastify';

import {quotesAdvices} from "../data/quotes-advices";
import {getRandomStringFromArray} from "../utils/get-random-string-from-array";

function splitAdvice(message: string): { quoteText: string; autorText: string } | null {
    const regex = /«(.*)?»\s—\s(.*)/gm;
    const match = regex.exec(message);
    if (match && match.length >= 3) {
        return {
            quoteText: match[1], // Первая группа (в кавычках)
            autorText: match[2], // Вторая группа (с "—")
        };
    }
    // Если совпадений нет, возвращаем null
    console.log('Не удалось распарсить строку: '+message);
    return null;
}

const renderAdvice =
  (message: string) =>
  () => {
    const Icon = LightBulbIcon;//RocketLaunchIcon;
    const splittedAdvice = splitAdvice(message);
    if (splittedAdvice) {
        return (
            <Alert icon={<Icon className="text-white w-[24px]"/>} variant="gradient">
                <p>{splittedAdvice.quoteText}</p>
                <p className="mt-2 text-right italic">{splittedAdvice.autorText}</p>
            </Alert>
    );
    } else {
        return null;
    }
  };

export const useQuoteAdvice = () => {
  const quoteAdvice = getRandomStringFromArray(quotesAdvices);
  const showQuoteAdvice = useCallback(() => {
    toast(renderAdvice(quoteAdvice), {
      toastId: quoteAdvice,
      autoClose: 15000
    });
  }, []);

  return { showQuoteAdvice };
};
